<template>
  <div
    class="form container my-10"
  >
    <h2>{{ isEdit ? 'Редактировать' :'Добавить' }} договор {{ isEdit ? `[${params.item.contract_number}]` :'' }}</h2>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="form.contract_number"
          placeholder="Договор"
          background-color="white"
          dense
          outlined
          hide-details="true"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.name"
          placeholder="Название организации"
          class="px-4"
          dense
          outlined
          hide-details="true"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.unp"
          placeholder="УНП организации"
          class="px-4"
          dense
          outlined
          hide-details="true"
        />
      </v-col>

      <div class="d-flex">
        <v-col>
          <label>Начало договора</label>
          <custom-date-picker v-model="form.contract_d_on" />
        </v-col>
        <v-col>
          <label>Окончание договора</label>
          <custom-date-picker v-model="form.contract_d_off" />
        </v-col>
      </div>
    </v-row>
    <v-divider class="my-4" />
    <div class="d-flex mt-4">
      <v-btn
        class="mr-4"
        color="#5CB7B1"
        dark
        @click="submit"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="red"
        dark
        @click="clear"
      >
        Очистить
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CustomDatePicker from '@/components/shared/custom-date-picker.vue';
export default {
  components:{
    CustomDatePicker
  },
  mixins:[filterBySearchFieldMixin],
  props:{
    params:{
      required:false,
      default:null,
      validator: (prop) => typeof prop === "object" || prop === null,
    }
  },
  data:() => ({
    form:{
      id: null,
      division_id: null,
      name: null,
      unp: null,
      contract_d_on: null,
      contract_d_off: null,
      contract_number:null
    },
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    }),
    userId(){
      return this.user?.user?.id
    },
    isEdit(){
      return !!(this.params.item)
    },
    divisionId(){
      return this.user?.user?.activeDivisionId;
    }
  },
  watch:{
    params:{
      handler(nv){
        if(nv.item){
          this.setFields()
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods:{
    setFields(){
      const item = this.params.item
      Object.keys(this.form).forEach(key => {
        if(key in item){
          this.form[key] = item[key]
        }
      })
    },
    submit(){
      const valid = ['name', 'unp', 'contract_d_on', 'contract_d_off', 'contract_number'].map(key => ![null, ""].includes(this.form[key]))
      if(valid.includes(false)){
        return this.$snackbar({text: "Имеются незаполенные поля",color:"red", top:false, right: false});
      }
      const payload = {
        ...this.form,
        user_id:this.userId,
        division_id: this.form.division_id ?? this.user?.user?.activeDivisionId,
      }
      this.$store.dispatch('division/saveSoClient', payload).then(() => {
        this.$snackbar({
          text: "Справочник обновлен",
          color: "blue",
          top:false, right: false
        });
        this.$emit('update:show', false)
      }).catch(err => {
        if(err.response.status === 400){
          this.$snackbar({text: err.response.data || "Ошибка",color:"red", top:false, right: false});
        }else{
          this.$snackbar({text: "Ошибка",color:"red", top:false, right: false});
        }
      });
    },
    clear(){
      Object.keys(this.form).forEach(key => {
        this.form[key] = null
      })
    }
  }
}
</script>
<style scoped>
  .form{
    width:100%;
    min-height: calc(100vh - 129px)
  }
</style>
